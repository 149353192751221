import React from 'react';
import './Location.css';
import lonavlaImage from '../assets/img1.jpg';
import karjatImage from '../assets/img1.jpg';
import puneImage from '../assets/img1.jpg';
import mumbaiImage from '../assets/img1.jpg';
import alibaugImage from '../assets/img1.jpg';
import mahabaleshwarImage from '../assets/img1.jpg';
import igatpuriImage from '../assets/img1.jpg';
import goaImage from '../assets/img1.jpg';
import manaliImage from '../assets/img1.jpg';
import shimlaImage from '../assets/img1.jpg';

const locations = [
    { name: 'Lonavla', image: lonavlaImage },
    { name: 'Karjat', image: karjatImage },
    { name: 'Pune', image: puneImage },
    { name: 'Mumbai', image: mumbaiImage },
    { name: 'Alibaug', image: alibaugImage },
    { name: 'Mahabaleshwar', image: mahabaleshwarImage },
    { name: 'Igatpuri', image: igatpuriImage },
    { name: 'Goa', image: goaImage },
    { name: 'Manali', image: manaliImage },
    { name: 'Shimla', image: shimlaImage }
];

function Location() {
    return (
        <div className="location-container">
            <div className="bg-instruments">🎸 🥁 🎷 🎺 🎻 🎤 🎹 🎼 🎧</div>
            <div className="bg-text1">OUR HOME</div>
            <h2 className="title">
                <i className="fas fa-map-marker-alt location-icon"></i> {/* Font Awesome map icon */}
                Locations 
                <i className="fas fa-map-marker-alt location-icon"></i>
            </h2>
            <p className="intro-text">Are you ready to experience the magic of live music like never before? <br />Whether you’re hosting a party, event, or simply looking for a night of fun, The Band Company is here to take you on a musical journey.</p>
            <div className="location-grid">
                {locations.map((location, index) => (
                    <div key={index} className="location-card">
                        <img src={location.image} alt={location.name} className="location-image" />
                        <div className="location-name">{location.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Location;
