// BandmatesSection.js
import React from 'react';
import './BandmatesSection.css';

const bandmates = [
  {
    id: 1,
    name: 'John Doe',
    role: 'Lead Guitarist',
    image: 'path/to/john-image.jpg', // Replace with actual image paths
  },
  {
    id: 2,
    name: 'Jane Smith',
    role: 'Drummer',
    image: 'path/to/jane-image.jpg',
  },
  {
    id: 3,
    name: 'Mike Brown',
    role: 'Bassist',
    image: 'path/to/mike-image.jpg',
  },
  {
    id: 4,
    name: 'Sarah Green',
    role: 'Vocalist',
    image: 'path/to/sarah-image.jpg',
  },
];

const BandmatesSection = () => {
  return (
    <section className="bandmates-section">
      <div className="background-heading5">LIFE OUR</div>
      <h2 className="section-heading">Our Bandmates</h2>

      <div className="bandmates-container">
        {bandmates.map((mate) => (
          <div key={mate.id} className="bandmate-card">
            <div className="image-overlay">
              <img src={mate.image} alt={mate.name} className="bandmate-image" />
            </div>
            <div className="bandmate-details">
              <h3 className="bandmate-name">{mate.name}</h3>
              <p className="bandmate-role">{mate.role}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BandmatesSection;
