import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'; // Import social icons
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai'; // Import phone and email icons
import { MdLocationOn } from 'react-icons/md'; // Import location icon
import './Footer.css'; // Import the CSS file
import logo from "../assets/logo.png"
import TermsAndConditions from './T&C';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* First Column */}
                <div className="footer-column">
                    <div className="footer-card">
                        <img src={logo} alt="Logo" className="footer-logo" /> {/* Update with your logo path */}
                        <p className="footer-description">
                            Welcome to Arka Energy, your trusted partner in solar energy solutions.
                        </p>
                        <button className="footer-button">Get in Touch</button>
                        <div className="footer-social-icons">
                            <a href="#" className="social-icon"><FaFacebookF /></a>
                            <a href="#" className="social-icon"><FaTwitter /></a>
                            <a href="#" className="social-icon"><FaLinkedinIn /></a>
                        </div>
                    </div>
                </div>

                {/* Second Column */}
                <div className="footer-column" style={{textAlign:'center'}}>
                    <h3>Pages</h3>
                    <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/location">Location</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    <li><a href="/reviews">Reviews</a></li>
                 
                    <li><a href="/blogs">Blog</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                    </ul>
                </div>

                {/* Third Column */}
                <div className="footer-column" >
                    <h3 >Get In Touch</h3>
                    <p><AiFillPhone /> 7276136171</p>
                    <p><AiOutlineMail /> contact@arkaenergy.info</p>
                    <p><MdLocationOn /> D4 Bldg, 4 Sakalnagar, Baner Road, Aundh, Pune 411007</p>
                </div>

                {/* Fourth Column */}
                <div className="footer-column">
                    <h3>Map</h3>
                    <div className="map-container">
                        <iframe
                            title="Location Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.276243825489!2d74.52117061584537!3d18.55677536882769!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c05212cb1f5b%3A0x98af0b5b49eac1a6!2sD4%20Building%2C%204%20Sakalnagar%2C%20Baner%20Road%2C%20Aundh%2C%20Pune%20411007!5e0!3m2!1sen!2sin!4v1686871814856!5m2!1sen!2sin"
                            width="250"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} The Band Company. All rights reserved.</p>
            </div>
            <div className="footer-bottom1">
    <a href="/t&c" className="footer-link">Terms & Conditions</a> 
    <span className="footer-separator"> | </span>
    <a href="/privacypolicy" className="footer-link">Privacy Policy</a>
    <span className="footer-separator"> | </span>
    <a href="/disclaimer" className="footer-link">Disclaimer</a>
</div>

           
            

        </footer>
    );
};

export default Footer;
