import React from 'react';
import './Gallery.css';

// Importing images from assets folder
import ban1 from '../assets/ban1.jpg';
import ban2 from '../assets/ban2.jpg';
import ban3 from '../assets/ban1.jpg';
import ban4 from '../assets/ban2.jpg';
import ban5 from '../assets/ban1.jpg';
import ban6 from '../assets/ban2.jpg';
import ban7 from '../assets/ban1.jpg';
import ban8 from '../assets/ban2.jpg';
import ban9 from '../assets/ban2.jpg';
import ban10 from '../assets/ban2.jpg';

const Gallery = () => {
  // Array of image sources
  const images = [ban1, ban2, ban3, ban4, ban5, ban6, ban7, ban8, ban9, ban10];

  return (
    <div className="gallery">
      {/* Header with animation and background text */}
      <div className="gallery-header">
        <h1 className="icon-heading">
          <span className="icon">🎶</span> GALLERY 🎶
        </h1>
        <h1 className="bg-textgallery">OUR MEMORIES</h1>
      </div>

      {/* Image grid layout */}
      <div className="gallery-grid">
        {images.map((image, index) => {
          // Assign custom classes for specific images based on their index
          let additionalClass = '';
          if ([1].includes(index)) {
            additionalClass = 'margin-top-1';
          } else if ([2].includes(index)) {
            additionalClass = 'margin-top-2';
          } else if ([3].includes(index)) {
            additionalClass = 'margin-top-3';
          } else if ([4].includes(index)) {
            additionalClass = 'margin-top-4';
          }
          else if ([6].includes(index)) {
            additionalClass = 'margin-top-6';
          }
          else if ([5].includes(index)) {
            additionalClass = 'margin-top-5';
          }
          else if ([8].includes(index)) {
            additionalClass = 'margin-top-6';
          }else if ([9].includes(index)) {
            additionalClass = 'margin-top-5';
          }

          return (
            <div key={index} className={`gallery-item ${additionalClass}`}>
              <img src={image} alt={`Gallery ${index + 1}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
