import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./BandPage.css";

// Import individual band image
import soloBandImg from "../assets/ban1.jpg"; // Change image accordingly
import { FaMusic } from "react-icons/fa"; // Music icon for left and right sides

const SoloBandPage = () => {
  useEffect(() => {
    // Add fade-in animation on page load
    const page = document.querySelector(".band-page-container");
    page.classList.add("fade-in");
  }, []);

  return (
    <div className="band-page-container">
      <div className="band-header">
        <div className="band-img-container">
          <img src={soloBandImg} alt="Solo Band" className="band-img" />
        </div>
        <div className="band-info">
          <h1 className="band-name">
            <FaMusic className="music-icon left" />
            Solo Band
            <FaMusic className="music-icon right" />
          </h1>
          <p className="band-tagline">Intimate, Engaging, and Powerful Performances</p>
        </div>
      </div>
      <div className="band-description">
        <h2>About the Band</h2>
        <p>
          The Solo Band is known for its intimate and engaging performances, 
          featuring a single artist who creates a dynamic and energetic atmosphere 
          for all types of events. Whether it's an acoustic set or a more upbeat
          performance, this band is perfect for smaller venues or private parties.
        </p>
        <p><strong>Genre:</strong> Pop, Rock, Acoustic</p>
        <p><strong>Available for:</strong> Weddings, Corporate Events, Private Parties</p>
      </div>
      <div className="band-footer">
        <div className="price-container">
          <p className="price">Price: ₹12,000</p>
        </div>
        <div className="action-buttons">
          <Link to="/enquire" className="btn enquire-btn">Enquire Now</Link>
          <a href="https://wa.me/1234567890" className="btn whatsapp-btn">Whatsapp</a>
        </div>
      </div>
    </div>
  );
};

export default SoloBandPage;
