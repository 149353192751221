import React, { useState } from 'react';
import './ClientsCarousel.css';

const clients = [
  {
    id: 1,
    logo: "../assets/client1.webp", // Replace with your actual logo path
  },
 
  // Add more client logos as needed
];

const ClientsCarousel = () => {
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % clients.length);
  };

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex - 1 + clients.length) % clients.length);
  };

  return (
    <div className="client-section">
      <div className="background-heading1">PARTNERS</div>
      <h2 className="main-heading1">Our Clients</h2>
      
      <div className="carousel-container">
        <div className="arrow left-arrow" onClick={prevSlide}>&#10094;</div>
        
        <div className="client-cards">
          {clients.slice(index, index + 1).map((client) => (
            <div key={client.id} className="client-card">
              <img src={client.logo} alt="Client logo" className="client-logo" />
            </div>
          ))}
        </div>

        <div className="arrow right-arrow" onClick={nextSlide}>&#10095;</div>
      </div>
    </div>
  );
};

export default ClientsCarousel;
