import React from 'react';
import './Services.css';
import backgroundVideo from '../assets/video1.mp4'; // Update this path with the correct video file path

function Services() {
    return (
        <div className="services-page">
            {/* Hero Section with Background Video */}
            <div className="hero-section">
                <video autoPlay loop muted className="hero-video">
                    <source src={backgroundVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <h1 className="hero-title">OUR SERVICES</h1>
                <div className="bg-text">SERVICES</div> {/* Optional Background Text */}
            </div>
            
            {/* Service sections */}
            <div className="service-section">
                <div className="instrument-icon icon1 fa fa-guitar"></div>
                <div className="instrument-icon icon2 fa fa-drum"></div>
                <div className="instrument-icon icon3 fa fa-microphone"></div>
                <div className="instrument-icon icon4 fa fa-violin"></div>
                <div className="instrument-icon icon5 fa fa-piano"></div>
                <div className="instrument-icon icon6 fa fa-trumpet"></div>
                <div className="instrument-icon icon7 fa fa-saxophone"></div>
                <div className="instrument-icon icon8 fa fa-bass"></div>
                <div className="instrument-icon icon9 fa fa-clarinet"></div>
                <div className="instrument-icon icon10 fa fa-cello"></div>
                <div className="instrument-icon icon11 fa fa-banjo"></div>
                <div className="instrument-icon icon12 fa fa-accordion"></div>
                <div className="bg-text">MUSIC</div>
                <div className="service-icon">🎶</div>
                <h2>Music Production</h2>
                <p>Professional music production for albums, singles, and everything in between. Let us help you create the perfect sound.</p>
                <a href="#merch" className="merch-link">Explore Merch</a>
            </div>

            <div className="service-section">
                <div className="instrument-icon icon13 fa fa-violin"></div>
                <div className="instrument-icon icon14 fa fa-microphone"></div>
                <div className="bg-text">EVENTS</div>
                <div className="service-icon">🎤</div>
                <h2>Live Performances</h2>
                <p>We bring live music to events of all sizes. Book us for concerts, festivals, and private events.</p>
                <a href="#booking" className="booking-link">Book Now</a>
            </div>

            <div className="service-section">
                <div className="instrument-icon icon15 fa fa-guitar"></div>
                <div className="instrument-icon icon16 fa fa-drum"></div>
                <div className="bg-text">MERCH</div>
                <div className="service-icon">🛒</div>
                <h2>Merchandise</h2>
                <p>Get your hands on exclusive band merchandise. From t-shirts to vinyl, we've got something for every fan.</p>
                <a href="#merch" className="merch-link">Shop Now</a>
            </div>
        </div>
    );
}

export default Services;
