import React, { useEffect, useState } from 'react';
import './AboutUs.css';
import video from '../assets/video1.mp4'; // Adjust the import as needed
import img1 from '../assets/img1.jpg';
import img2 from '../assets/img2.jpg';
import ClientsCarousel from './ClientsCarousel'; // Import the ClientsCarousel
import TestimonialCarousel from './TestimonialCarousel';
import BandmatesSection from './BandmatesSection';

const AboutUs = () => {
    const [projects, setProjects] = useState(0);
    const [clients, setClients] = useState(0);
    const [experience, setExperience] = useState(0);

    useEffect(() => {
        const countProjects = setInterval(() => {
            setProjects((prev) => (prev < 3525 ? prev + 1 : prev));
        }, 2);

        const countClients = setInterval(() => {
            setClients((prev) => (prev < 2000 ? prev + 1 : prev));
        }, 2);

        const countExperience = setInterval(() => {
            setExperience((prev) => (prev < 8 ? prev + 1 : prev));
        }, 250);

        return () => {
            clearInterval(countProjects);
            clearInterval(countClients);
            clearInterval(countExperience);
        };
    }, []);

    return (
        <div className="about-us">
            {/* Background Video */}
            <div className="about-heading">
                <video autoPlay loop muted className="background-video">
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <h1 >About Us</h1>
            </div>
            
            {/* Stats Section */}
            <div className="stats-section">
               
                <div className="stat">
                    <h2>{clients}+</h2>
                    <p>Our Shows</p>
                </div>
                <div className="stat">
                    <h2>{experience}+</h2>
                    <p>Years of Experience</p>
                </div>
            </div>

            {/* Company Overview Section */}
            <div className="overview-section">
                <h1 className="background-heading">OUR STORY</h1>
                <div className="side-text">
                    <p>THE BAND COMPANY</p>
                </div>
                <div className="content">
                    <h2>We Craft Musical Journeys That are Louder, Deeper, Daring, and Unforgettable.</h2>
                    <p>
                    At The Band Company, we are passionate about sharing the joy of live music with audiences of all kinds. Founded by a group of talented musicians, our mission is to create unforgettable experiences through captivating performances at picturesque tourist locations and personalized doorstep shows.
<br/><br/>
With a diverse repertoire that spans genres and styles, we cater to every occasion, ensuring that our music resonates with each audience. We believe in the power of live music to bring people together and create lasting memories. Join us on this musical journey, and let’s make your next event truly special!

                    </p>
                </div>
                <div className="image-section">
                    <img 
                        src={img2} 
                        alt="Description for image 1" 
                        style={{ width: '100%', maxWidth: '450px', height: '380px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.3s ease-in-out', marginTop: '1rem' }} 
                    />
                </div>
            </div>
            
            <div className="clients-section">
               
                <ClientsCarousel /> {/* Add the ClientsCarousel component here */}
            </div>
            <div className="bandmates-section">
               
                <BandmatesSection /> {/* Add the ClientsCarousel component here */}
            </div>
            

            {/* Clients Carousel Section */}
            
            <div className="testimonial-section">
               
                <TestimonialCarousel /> {/* Add the ClientsCarousel component here */}
            </div>
        </div>
    );
};

export default AboutUs;
