import React, { useState } from 'react';
import './Navbar.css';
import 'font-awesome/css/font-awesome.min.css';
import MusicPlayer from './MusicPlayer';
import logo from '../assets/logo.png';
import logo1 from '../assets/logo1.png';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={`navbar ${isMenuOpen ? 'navbar-open' : ''}`}>
            <div className="navbar-container">
                <div className="brand-logo">
                    <img src={logo1} alt="Pasay Invento" />
                </div>
                <ul className="navbar-menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/location">Location</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    <li><a href="/reviews">Reviews</a></li>
                    <li><a href="/blogs">Blog</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                </ul>
                <div className="icon-container">
                    <div className="hamburger-icon" onClick={toggleMenu}>
                        <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
                    </div>
                </div>
            </div>
            <div className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
                <div className="sidebar-content">
                    <div className="sidebar-logo">
                        <img src={logo} alt="Pasay Invento" />
                    </div>
                    <p>We specialize in delivering live music experiences at your stunning locations and right at your doorstep. Our talented musicians bring a diverse repertoire to every event, ensuring unforgettable moments filled with joy and energy.</p>
                    <div className="working-hours">
                        <h4>Working hours</h4>
                        <p>Monday - Friday: 9:30 - 18:30 Hours</p>
                        <p>Saturday: 9:30 - 18:30 Hours</p>
                        <p>Sunday: Closed</p>
                    </div>
                    <div className="social-links">
                        <a href="#" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                        <a href="#" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                        <a href="#" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                {/* Music Player at the Bottom */}
                <MusicPlayer />
            </div>
        </nav>
    );
}

export default Navbar;
