// PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="privacy-container">
      <h1>Disclaimer for The Band Company</h1>
      <p><strong>Effective Date:</strong> {currentDate}</p>

      <section>
        <h2>1. General Information</h2>
        <p>
          The information provided on this website is for general informational purposes only. While we strive to ensure the accuracy of the content, The Band Company makes no representations or warranties, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information.
        </p>
      </section>

      <section>
        <h2>2. No Professional Advice</h2>
        <p>
          The content provided on this website is not intended as professional advice. You should not rely on it as a substitute for professional consultation. Any reliance you place on such information is strictly at your own risk.
        </p>
      </section>

      <section>
        <h2>3. External Links</h2>
        <p>
          Our website may contain links to external websites that are not controlled or maintained by The Band Company. We are not responsible for the content or reliability of any linked websites and do not necessarily endorse the views expressed within them.
        </p>
      </section>

      <section>
        <h2>4. Limitation of Liability</h2>
        <p>
          The Band Company will not be held liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from the use of this website or the information provided herein.
        </p>
      </section>

      <section>
        <h2>5. Changes to This Disclaimer</h2>
        <p>
          We reserve the right to amend or update this Disclaimer at any time without prior notice. Any changes will be reflected on this page with an updated effective date.
        </p>
      </section>

      <section>
        <h2>6. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Disclaimer, please contact us at:
        </p>
        <p>Phone: 7779945379</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
