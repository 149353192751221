import React from 'react';
import './T&C.css';

function TermsAndConditions() {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions for The Band Company</h1>

      <section>
        <h2>1. Agreement Overview</h2>
        <p>These terms and conditions govern the services provided by The Band Company for performances at tourist locations and doorstep shows.</p>
      </section>

      <section>
        <h2>2. Booking and Payment</h2>
        <p>A non-refundable deposit is required to secure your booking.</p>
        <p>Full payment must be made at least [X] days prior to the performance date.</p>
        <p>Payments can be made via [accepted payment methods].</p>
      </section>

      <section>
        <h2>3. Performance Details</h2>
        <p>The performance duration will be [specify duration] unless otherwise agreed upon.</p>
        <p>Specific performance times and locations must be confirmed at least [X] days in advance.</p>
        <p>The Band Company reserves the right to adjust performance details due to unforeseen circumstances, with prior notice to the client.</p>
      </section>

      <section>
        <h2>4. Cancellation Policy</h2>
        <p>Cancellations made [X] days before the performance will incur a [percentage]% cancellation fee.</p>
        <p>Cancellations within [X] days of the performance will forfeit the deposit.</p>
      </section>

      <section>
        <h2>5. Client Responsibilities</h2>
        <p>Clients must provide a suitable performance space, ensuring it complies with local regulations.</p>
        <p>Access to electricity and facilities must be ensured for the band’s equipment.</p>
        <p>Clients are responsible for securing any necessary permits for performances in public locations.</p>
      </section>

      <section>
        <h2>6. Liability</h2>
        <p>The Band Company is not liable for any injury or damage incurred during the performance.</p>
        <p>Clients must ensure that the performance area is safe for all attendees.</p>
      </section>

      <section>
        <h2>7. Force Majeure</h2>
        <p>The Band Company is not responsible for any failure to perform due to circumstances beyond its control, including but not limited to natural disasters, government restrictions, or other emergencies.</p>
      </section>

      <section>
        <h2>8. Amendments</h2>
        <p>These terms and conditions may be updated periodically. Clients will be notified of any significant changes.</p>
      </section>

      <section>
        <h2>9. Governing Law</h2>
        <p>These terms and conditions are governed by the laws of [your jurisdiction].</p>
      </section>

      <section>
        <h2>10. Acceptance of Terms</h2>
        <p>By booking a performance with The Band Company, you acknowledge that you have read and agree to these terms and conditions.</p>
      </section>
    </div>
  );
}

export default TermsAndConditions;
