import React, { useState } from "react";
import "./Doors.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// Import sample images for locations
import lonavlaImg from "../assets/lonavla.png";
import karjatImg from "../assets/lonavla.png";
import puneImg from "../assets/lonavla.png";
import mumbaiImg from "../assets/lonavla.png";
import alibaugImg from "../assets/lonavla.png";
import mahabaleshwarImg from "../assets/lonavla.png";
import igatpuriImg from "../assets/lonavla.png";
import goaImg from "../assets/lonavla.png";
import manaliImg from "../assets/lonavla.png";
import shimlaImg from "../assets/lonavla.png";

const Doors = () => {
  const locations = [
    { name: "Lonavla", img: lonavlaImg },
    { name: "Karjat", img: karjatImg },
    { name: "Pune", img: puneImg },
    { name: "Mumbai", img: mumbaiImg },
    { name: "Alibaug", img: alibaugImg },
    { name: "Mahabaleshwar", img: mahabaleshwarImg },
    { name: "Igatpuri", img: igatpuriImg },
    { name: "Goa", img: goaImg },
    { name: "Manali", img: manaliImg },
    { name: "Shimla", img: shimlaImg },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? locations.length - 5 : prevIndex - 5
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 5 >= locations.length ? 0 : prevIndex + 5
    );
  };

  const currentCards = locations.slice(currentIndex, currentIndex + 5);

  return (
    <div className="doors-container">
      <h2 className="doors-heading">OUR DOORS</h2>
      <div className="doors-content">
        <button className="arrow-btn left-arrow" onClick={handlePrev}>
          <FaArrowLeft />
        </button>
        <div className="doors-grid">
          {currentCards.map((location, index) => (
            <div key={index} className="door-item">
              <img src={location.img} alt={location.name} className="door-image" />
              <p className="door-location">{location.name}</p>
            </div>
          ))}
        </div>
        <button className="arrow-btn right-arrow" onClick={handleNext}>
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Doors;
