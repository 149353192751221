import React, { useState, useEffect } from 'react';

function MusicPlayer() {
    const [isPlaying, setIsPlaying] = useState(true);
    const audio = new Audio('/path/to/your-music-file.mp3'); // Add your audio file path

    useEffect(() => {
        if (isPlaying) {
            audio.play();
        } else {
            audio.pause();
        }

        // Clean up on component unmount
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [isPlaying]);

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="music-player">
            <button className="play-pause-btn" onClick={togglePlayPause}>
                <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
            </button>
            <div className="music-info">
                <p className="music-title">THE BAND COMPANY</p>
            </div>
        </div>
    );
}

export default MusicPlayer;
