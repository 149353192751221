// PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy for The Band Company</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>

      <section>
        <h2>1. Introduction</h2>
        <p>The Band Company values your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with us through our website, bookings, and performances.</p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, phone number, and payment details when you book our services.</li>
          <li><strong>Non-Personal Information:</strong> Browser type, IP address, and usage data collected through cookies and analytics.</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Process bookings and payments.</li>
          <li>Communicate with you regarding your booking and provide customer support.</li>
          <li>Improve our services and website.</li>
          <li>Send promotional materials, if you have opted in.</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Sharing and Disclosure</h2>
        <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
        <ul>
          <li>Service providers who assist us in delivering our services (e.g., payment processors).</li>
          <li>Legal authorities when required by law.</li>
        </ul>
      </section>

      <section>
        <h2>5. Data Security</h2>
        <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request correction of inaccurate information.</li>
          <li>Request deletion of your personal information, subject to legal obligations.</li>
        </ul>
      </section>

      <section>
        <h2>7. Cookies</h2>
        <p>Our website uses cookies to enhance user experience. You can manage your cookie preferences through your browser settings.</p>
      </section>

      <section>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated effective date.</p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p>[Phone Number: 7779945379]</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
