import React, { useState } from 'react';
import './TestimonialCarousel.css';

const testimonials = [
  {
    id: 1,
    text: "Working with Pasay Invento has been a game-changer for our brand. Their social marketing strategies have helped us reach our target audience, increase our online visibility, and drive significant growth in our business.",
    logo: "path/to/logo1.png", // Replace with your actual logo path
  },
  {
    id: 2,
    text: "We had tried various social marketing agencies before, but none delivered results like Pasay Invento. Their team’s expertise & creativity have significantly improved our brand's online reputation & customer engagement.",
    logo: "path/to/logo2.png", // Replace with your actual logo path
  },
  {
    id: 3,
    text: "Choosing Pasay Invento as our social marketing partner was one of the best decisions. Our social media presence has never been stronger. They truly go above and beyond to understand our business and goals.",
    logo: "path/to/logo3.png", // Replace with your actual logo path
  },
];

const TestimonialCarousel = () => {
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 2) % testimonials.length);
  };

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex - 2 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="testimonial-section">
      <div className="background-heading2">FEEDBACK</div>
      <h2 className="main-heading">Our Testimonials</h2>
      
      <div className="carousel-container">
        <div className="arrow left-arrow" onClick={prevSlide}>&#10094;</div>
        
        <div className="testimonial-cards">
          {testimonials.slice(index, index + 2).map((testimonial) => (
            <div key={testimonial.id} className="testimonial-card">
              <p className="testimonial-text">"{testimonial.text}"</p>
              <img src={testimonial.logo} alt="Company logo" className="testimonial-logo" />
            </div>
          ))}
        </div>

        <div className="arrow right-arrow" onClick={nextSlide}>&#10095;</div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
