import React from 'react';
import './Blogs.css';
import blog1 from '../assets/blog1.jpg'; // Replace with your image paths
import blog2 from '../assets/blog2.jpg';
import blog3 from '../assets/blog3.jpg';

const Blogs = () => {
    const blogPosts = [
        {
            title: "Our Journey: From Garage to Stage",
            image: blog1,
            description: "Discover how we started as a small garage band and grew into a vibrant music group.",
            link: "#"
        },
        {
            title: "Behind the Scenes of Our Latest Album",
            image: blog2,
            description: "Take a peek behind the scenes of our recent album production and the creative process involved.",
            link: "#"
        },{
            title: "Behind the Scenes of Our Latest Album",
            image: blog2,
            description: "Take a peek behind the scenes of our recent album production and the creative process involved.",
            link: "#"
        },{
            title: "Behind the Scenes of Our Latest Album",
            image: blog2,
            description: "Take a peek behind the scenes of our recent album production and the creative process involved.",
            link: "#"
        },{
            title: "Behind the Scenes of Our Latest Album",
            image: blog2,
            description: "Take a peek behind the scenes of our recent album production and the creative process involved.",
            link: "#"
        },
        {
            title: "Upcoming Concerts: Join Us Live!",
            image: blog3,
            description: "Get the latest updates on our upcoming concerts and how to get your tickets.",
            link: "#"
        }
    ];

    return (
        <div className="blogs">
            <div className="background-text">BAND JOURNEY</div>
            <header className="blog-header">
                <h1 className="bounce"><i className="fas fa-music"></i> Our Latest Blogs</h1>
                <p className="fade-in">Explore insights and updates from our band journey.</p>
            </header>

            <main className="blog-container">
                {blogPosts.map((post, index) => (
                    <article className="blog-post" key={index}>
                        <img src={post.image} alt={post.title} />
                        <h2 className="fade-in">{post.title}</h2>
                        <p className="fade-in">{post.description}</p>
                        <a href={post.link} className="read-more">Read More <i className="fas fa-arrow-right"></i></a>
                    </article>
                ))}
            </main>
        </div>
    );
};

export default Blogs;
