import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <div className="contact-us">
            {/* Hero Section with Video Background */}
            <section className="hero1">
                <video className="hero-video1" autoPlay loop muted>
                    <source src={require('../assets/video1.mp4')} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="hero-content">
                    <h1 className="hero-heading">
                        <i className="fas fa-headset"></i> Contact Us
                    </h1>
                    <p className="hero-subheading">We'd love to hear from you!</p>
                </div>
            </section>

            {/* Contact Section with Form and Map */}
            <section className="contact-details">
                <div className="contact-info">
                    <h2>Get in Touch</h2>
                    <p>
                    We’d love to hear from you! Whether you have questions about our performances, want to book a show, or just want to chat about music, our team is here to help. Reach out to us via email or phone, and we’ll get back to you as soon as possible. Let’s create some unforgettable musical moments together!

                    </p>
                    <div className="contact-methods">
                        <p><strong>Address:</strong> 123 Music Street, Melody City</p>
                        <p><strong>Email:</strong> contact@yourband.com</p>
                        <p><strong>Phone:</strong> (123) 456-7890</p>
                        
                        {/* Stacked Individual Social Icons */}
                        <div className="social-icons">
                            <a href="#" aria-label="Facebook" className="social-icon"><i className="fab fa-facebook"></i></a>
                            <a href="#" aria-label="Twitter" className="social-icon"><i className="fab fa-twitter"></i></a>
                            <a href="#" aria-label="Instagram" className="social-icon"><i className="fab fa-instagram"></i></a>
                            <a href="#" aria-label="YouTube" className="social-icon"><i className="fab fa-youtube"></i></a>
                        </div>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345099196!2d144.9537353153184!3d-37.81720997975116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f9f3e33%3A0x5045675218ceed30!2s123%20Music%20Street%2C%20Melody%20City!5e0!3m2!1sen!2sau!4v1634346800447!5m2!1sen!2sau"
                            width="100%" 
                            height="200" 
                            style={{ border: 0 }} 
                            allowFullScreen 
                            loading="lazy" 
                            title="Google Map"
                        ></iframe>
                    </div>
                </div>

                <form className="contact-form" action="https://formspree.io/f/yourformid" method="POST">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" required />

                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" required />

                    <label htmlFor="phone">Phone Number</label>
                    <input type="tel" id="phone" name="phone" required />

                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" rows="5" required></textarea>

                    <button type="submit" className="submit-button">Send Message</button>
                </form>
            </section>
        </div>
    );
};

export default ContactUs;
