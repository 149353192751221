import React from "react";
import { Link } from "react-router-dom";
import "./Catalog.css";

// Import images for bands
import soloBandImg from "../assets/ban1.jpg";
import duetBandImg from "../assets/ban1.jpg";
import threePieceBandImg from "../assets/ban1.jpg";
import fullBandImg from "../assets/ban1.jpg";

const Catalog = () => {
  const bands = [
    {
      name: "Solo Band",
      img: soloBandImg,
      link: "/BandPage", // Link to the Solo Band details page
    },
    {
      name: "Duet Band",
      img: duetBandImg,
      link: "/BandPage",
    },
    {
      name: "3-Piece Band",
      img: threePieceBandImg,
      link: "/BandPage",
    },
    {
      name: "Full Fledge Live Band",
      img: fullBandImg,
      link: "/BandPage",
    },
  ];

  return (
    <div className="catalog-container">
      <h2 className="catalog-heading">Our Catalog</h2>
      <div className="catalog-grid">
        {bands.map((band, index) => (
          <div key={index} className="catalog-card">
            <Link to={band.link} className="catalog-link">
              <img src={band.img} alt={band.name} className="catalog-img" />
              <h3 className="bandname">{band.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Catalog;
