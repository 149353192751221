// EnquiryForm.js
import React, { useState } from 'react';
import './EnquiryForm.css';
import 'font-awesome/css/font-awesome.min.css';

const EnquiryForm = ({ closeModal }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        configuration: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // Submit the form data to Formspree
        fetch('https://formspree.io/f/xwpkpayn', { // Replace YOUR_FORM_ID with your actual Formspree ID
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formData,
                // Add any additional fields here if needed
            }),
        })
        .then((response) => {
            if (response.ok) {
                alert('Message sent successfully!');
                closeModal();
            } else {
                alert('An error occurred. Please try again.');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        });
    };

    return (
        <div className="enquiry-form-overlay">
            <div className="enquiry-form-container">
                <button className="close-btn" onClick={closeModal} aria-label="Close">
                    <i className="fa fa-times" aria-hidden="true"></i>
                </button>
                <h2>Enquire Now</h2>
                <form onSubmit={sendEmail}>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Phone Number:
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Email ID:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Location:
                        <select
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Location</option>
                            <option value="Lonavla">Lonavla</option>
                            <option value="Karjat">Karjat</option>
                            <option value="Pune">Pune</option>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Alibaug">Alibaug</option>
                            <option value="Mahabaleshwar">Mahabaleshwar</option>
                            <option value="Igatpuri">Igatpuri</option>
                            <option value="Goa">Goa</option>
                            <option value="Manali">Manali</option>
                            <option value="Shimla">Shimla</option>
                        </select>
                    </label>
                    <label>
                        Configuration:
                        <select
                            name="configuration"
                            value={formData.configuration}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Configuration</option>
                            <option value="Solo">Solo</option>
                            <option value="Duet">Duet</option>
                            <option value="3-Piece">3-Piece</option>
                            <option value="4-Piece">4-Piece</option>
                            <option value="Full-Fledge Band">Full-Fledge Band</option>
                        </select>
                    </label>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default EnquiryForm;
